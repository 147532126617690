import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import { FETCH_CUSTOMER_TRANSACTION, DOWNLOAD_CUSTOMER_TRANSACTION, DOWNLOAD_PAYMENT_RECEIPT } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { SiMicrosoftexcel } from 'react-icons/si';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const CustomerTransaction = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const customerTransactionList = useSelector((state) => state.customerTransaction.customerTransaction);
  const page = useSelector((state) => state.customerTransaction.page);
  const totalData = useSelector((state) => state.customerTransaction.total);
  const limit = useSelector((state) => state.customerTransaction.limit);
  const totalPages = useSelector((state) => state.customerTransaction.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);

  const getCustomerTransaction = useCallback(
    (data) => {
      const transactionData = {
        ...data,
        id: customerId,
      };
      dispatch({ type: FETCH_CUSTOMER_TRANSACTION.REQUEST, payload: transactionData });
    },
    [customerId]
  );

  const downloadpaymentReceipt = useCallback(
    (data) => {
      const walletId = {
        id: data.id, // Pass the id from the function parameter
      };
      dispatch({
        type: DOWNLOAD_PAYMENT_RECEIPT.REQUEST,
        payload: walletId,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Payment_Receipt'}.pdf`);
            }
          }
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    getCustomerTransaction();
  }, []);

  const downloadCustomerTransactions = useCallback((data = {}) => {
    const customerTransactionsData = {
      id: customerId,
      excel: true,
      ...data,
    };
    dispatch({
      type: DOWNLOAD_CUSTOMER_TRANSACTION.REQUEST,
      payload: customerTransactionsData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Customer Transactions'}.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadFile = () => {
    downloadCustomerTransactions();
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCustomerTransaction(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomerTransaction(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomerTransaction(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <Card className="customerDetail-table">
            <Row className="customerBooking-table-heading-row">
              <Col xl={11} lg={11} md={11}>
                <div className="customerDetail-table-heading">
                  {isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.title')}
                </div>
              </Col>
              <Col xl={1} lg={1} md={1}>
                {isLoading ? (
                  <div className="loader--block">
                    <Skeleton height={15} width={100} />
                  </div>
                ) : (
                  <div className="customerBooking-excel-icon" onClick={downloadFile}>
                    {isLoading ? <Skeleton height={30} width={30} circle="true" /> : <SiMicrosoftexcel size={35} color={'#5dad5d'} />}
                  </div>
                )}
              </Col>
            </Row>
            <div className="customerDetail-list-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.paymentType')}</span>
                          <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.reason')}</span>
                          <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.message')}</span>
                          <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.paymentPath')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.orderID')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.bookingID')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.voucherCode')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.assigner')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.comment')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.previousBalance')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.transactionAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.updatedBalance')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.dateTime')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerManagement.downloadReport')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(customerTransactionList) ? (
                      <tr>
                        <td colSpan={8} className="border-0">
                          <div className="empty-data-block">{t('customerTransaction.noData')}</div>
                        </td>
                      </tr>
                    ) : (
                      _.map(customerTransactionList, (item, index) => {
                        return (
                          <tr key={`transaction-data-item-${index}`}>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : index + 1}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'wallet_type', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'message', '-')}</td>

                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.razorpay_order_id') ? 'Razorpay' : '-'}</td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : _.get(item, 'invoice.razorpay_order_id') ? (
                                _.get(item, 'invoice.razorpay_order_id')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.id', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.code', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.assigner.name', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'voucher.comment', '-')}</td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : _.get(item, 'current_balance', '') ? (
                                roundOfDigit(_.get(item, 'current_balance', ''), 2)
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : _.get(item, 'amount', '') ? (
                                roundOfDigit(_.get(item, 'amount', ''), 2)
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : _.get(item, 'type', '') === 'credit' ? (
                                roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount', ''), 2)
                              ) : (
                                roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount', ''), 2)
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.createdAt ? (
                                moment(item.createdAt).utc(true).format('DD/MM/YYYY  H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {_.get(item, 'wallet_type') === 'WalletTopup' ? (
                                <button className="btn btn-sm btn-primary" onClick={() => downloadpaymentReceipt({ id: item.id })}>
                                  {t('customerManagement.downloadReport')}
                                </button>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!_.isEmpty(customerTransactionList) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </Card>
        </SkeletonTheme>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default CustomerTransaction;
