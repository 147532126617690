import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_REPORT_LIST, FETCH_REPORT_DOWNLOAD_REQUEST, CREATE_REPORT_DOWNLOAD_REQUEST, GET_SIGNED_URL } from 'actions/downloadAdvanceReport';

function* fetchReportList(action) {
  try {
    const response = yield call(API.fetchReportList, action.payload);
    yield put({ type: FETCH_REPORT_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_REPORT_LIST.FAIL, payload: { error: e } });
  }
}

function* fetchReportDownloadRequest(action) {
  try {
    const response = yield call(API.fetchReportDownloadRequest, action.payload);
    yield put({ type: FETCH_REPORT_DOWNLOAD_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_REPORT_DOWNLOAD_REQUEST.FAIL, payload: { error: e } });
  }
}

function* createReportDownloadRequest(action) {
  try {
    const response = yield call(API.createReportDownloadRequest, action.payload);
    yield put({ type: CREATE_REPORT_DOWNLOAD_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_REPORT_DOWNLOAD_REQUEST.FAIL, payload: { error: e } });
  }
}

function* getSignedUrl(action) {
  try {
    const response = yield call(API.getSignedUrl, action.payload);
    yield put({ type: GET_SIGNED_URL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_SIGNED_URL.FAIL, payload: { error: e } });
  }
}


function* reportDownloadRequest() {
  yield takeLatest(FETCH_REPORT_LIST.REQUEST, fetchReportList);
  yield takeLatest(FETCH_REPORT_DOWNLOAD_REQUEST.REQUEST, fetchReportDownloadRequest);
  yield takeLatest(CREATE_REPORT_DOWNLOAD_REQUEST.REQUEST, createReportDownloadRequest);
  yield takeLatest(GET_SIGNED_URL.REQUEST, getSignedUrl);
  
}

export default reportDownloadRequest;
