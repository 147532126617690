import { post, downloadFile } from 'utils/api';
import { CS_CHARGER_STATUS, CHARGING_STATIONS, WIDGETS } from './endpointURLs';

export const getChargerStatus = (data) => {
  const page = data.page ? data.page : 1;
  delete ['limit'];
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`${CS_CHARGER_STATUS}?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}&limit=20`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`${CS_CHARGER_STATUS}?page=${parseInt(page)}&search=${search}&limit=20`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`${CS_CHARGER_STATUS}?search=${search}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`${CS_CHARGER_STATUS}?page=${parseInt(page)}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`${CS_CHARGER_STATUS}?search=${search}&limit=20`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`${CS_CHARGER_STATUS}?sortBy=${sortBy}&limit=20`, data);
  } else {
    delete data['page'];
    return post(`${CS_CHARGER_STATUS}?page=${parseInt(page)}&limit=20`, data);
  }
};

export const downloadChargerStatus = (data) => {
  if (data.search) {
    const search = data.search;
    delete data['search'];
    return downloadFile(`${CS_CHARGER_STATUS}?search=${search}`, data);
  }
  return downloadFile(`${CS_CHARGER_STATUS}`, data);
};

export const getChargerWidgets = (data) => {
  const status = data.status;
  return post(`${CHARGING_STATIONS}${WIDGETS}?widgits_by_plug=${status}`, data);
};

export const chargerStatus = {
  getChargerStatus,
  downloadChargerStatus,
  getChargerWidgets,
};
