import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CancelReservationForm from './serverOperations/CancelReservationForm';
import ChangeAvailabilityForm from './serverOperations/ChangeAvailabilityForm';
import ConfigurationList from './serverOperations/ConfigurationList';
import GetDiagnosticsForm from './serverOperations/GetDiagnosticsForm';
import RemoteStartTransactionForm from './serverOperations/RemoteStartTransaction';
import RemoteStopTransactionForm from './serverOperations/RemoteStopTransaction';
import ReserveNowForm from './serverOperations/ReserveNowForm';
import ResetForm from './serverOperations/ResetForm';
import SendLocalListForm from './serverOperations/SendLocalListForm';
import TriggerMessageForm from './serverOperations/TriggerMessageForm';
import UnlockConnectorForm from './serverOperations/UnlockConnectorForm';
import UpdateFirmwareForm from './serverOperations/UpdateFirmwareForm';
import ClearCacheForm from './serverOperations/ClearCacheForm';
import GetLocalListVersionForm from './serverOperations/GetLocalListVersionForm';
import DataTransferServerForm from './serverOperations/DataTransferServerForm';
import SetChargingProfileForm from './serverOperations/SetChargingProgile';
import ClearChargingProfileForm from './serverOperations/ClearCharging';

const supportAccessibleTabs = [
  'clearCache',
  'remoteStartTransaction',
  'remoteStopTransaction',
  'reset',
  'triggerMessage'
];

const tabs = [
  { key: 'cancelReservation', label: 'Cancel Reservation', component: <CancelReservationForm /> },
  { key: 'changeAvailability', label: 'Change Availability', component: <ChangeAvailabilityForm /> },
  { key: 'setConfiguration', label: 'Get / Set Configuration', component: <ConfigurationList /> },
  { key: 'clearCache', label: 'Clear Cache', component: <ClearCacheForm /> },
  { key: 'getDiagnostics', label: 'Get Diagnostics', component: <GetDiagnosticsForm /> },
  { key: 'getLocalListVersion', label: 'Get Local List Version', component: <GetLocalListVersionForm /> },
  { key: 'remoteStartTransaction', label: 'Remote Start Transaction', component: <RemoteStartTransactionForm /> },
  { key: 'remoteStopTransaction', label: 'Remote Stop Transaction', component: <RemoteStopTransactionForm /> },
  { key: 'reserveNow', label: 'Reserve Now', component: <ReserveNowForm /> },
  { key: 'reset', label: 'Reset', component: <ResetForm /> },
  { key: 'sendLocalList', label: 'Send Local List', component: <SendLocalListForm /> },
  { key: 'triggerMessage', label: 'Trigger Message', component: <TriggerMessageForm /> },
  { key: 'unlockConnector', label: 'Unlock Connector', component: <UnlockConnectorForm /> },
  { key: 'updateFirmware', label: 'Update Firmware', component: <UpdateFirmwareForm /> },
  { key: 'dataTransferServer', label: 'Data Transfer Server', component: <DataTransferServerForm /> },
  { key: 'setchargingprofile', label: 'Set Charging Profile', component: <SetChargingProfileForm /> },
  { key: 'clearchargingprofile', label: 'Clear Charging Profile', component: <ClearChargingProfileForm /> },
];

const ServerOperationInfo = () => {
  const [currentTab, setCurrentTab] = useState('');
  const profileData = useSelector((state) => state.profile.userProfile);
  const userRole = profileData?.role || '';

  const handleTabClick = (e, tabKey) => {
    e.preventDefault();
    setCurrentTab(tabKey);
  };

  const visibleTabs = userRole === 'support'
    ? tabs.filter(tab => supportAccessibleTabs.includes(tab.key))
    : tabs;

  return (
    <div className="serverOperation_main_page">
      <div className="tab--block">
        <div className="tab-nav--block">
          {visibleTabs.map(tab => (
            <a
              key={tab.key}
              href="#"
              className={`tab-nav__item ${currentTab === tab.key ? 'active' : ''}`}
              onClick={(e) => handleTabClick(e, tab.key)}
            >
              {tab.label}
            </a>
          ))}
        </div>
        <div className="tab-content-block">
          <div className="tab-content___item active" id="station-details-tab">
            {visibleTabs.find(tab => tab.key === currentTab)?.component}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerOperationInfo;
