import React, { useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSave } from 'react-icons/bi';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import Pagination from 'components/general/Pagination';
import SearchBox from 'components/general/SearchBox';
import { CHANGE_CONFIGURATION, GET_CONFIGURATION } from 'actions/chargerOcpp';
import { toast } from 'react-toastify';
import TextInput from 'components/inputs/Input';
import { GoCircleSlash } from 'react-icons/go';
import { FETCH_CHARGER_LOG } from 'actions/activityLog';
import moment from 'moment';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const ConfigurationList = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [isLoader, setIsLoader] = useState();
  const [configurationValue, setConfigurationValue] = useState('');
  const [viewOnly, setViewOnly] = useState(false)
  const [dateTime, setDateTime] = useState(moment(moment.utc(new Date()).format()).toDate());
  const { t } = useTranslation();
  const getAllChargerLog = useSelector((state) => state.activityLog.activityLogs);
  const page = useSelector((state) => state.activityLog.page);
  const limit = useSelector((state) => state.activityLog.limit);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  // const totalData = useSelector((state) => state.activityLog.total);

  const viewChargerLog = useCallback(() => {

    const chargerLogData = {
      charger: chargerId,
      from: 'server',
      type: 'GetConfiguration',
      limit: 1,
    };

    setViewOnly(true)

    dispatch({
      type: FETCH_CHARGER_LOG.REQUEST,
      payload: chargerLogData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200 && _.isEmpty(_.get(res, 'data.results'))) {
            setIsLoader(true);
          } else {
            setIsLoader(false);
          }
        }
      },
    });
  }, [chargerId]);


  const getChargerLog = useCallback(
    (data = {}) => {
      if (dateTime) {
        const chargerLogData = {
          charger: chargerId,
          from: 'server',
          type: 'GetConfiguration',
          limit: 1,
          ...data,
        };

        setViewOnly(false)

        dispatch({
          type: FETCH_CHARGER_LOG.REQUEST,
          payload: chargerLogData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200 && _.isEmpty(_.get(res, 'data.results[0].response'))) {
                if (Date.now() - dateTime.getTime() < 120000) {
                  setIsLoader(true);
                  setViewOnly(true)
                  setTimeout(() => {
                  }, 3000);
                } else if (!_.get(res, 'data.results[0].response')) {
                  toast.error('Request time out');
                  setIsLoader(false);
                }
              } else {
                setIsLoader(false);
              }
            }
          },
        });
      } else {
        const chargerLogData = {
          charger: chargerId,
          from: 'server',
          type: 'GetConfiguration',
          limit: 1,
          ...data,
        };
        setViewOnly(false)
        dispatch({
          type: FETCH_CHARGER_LOG.REQUEST,
          payload: chargerLogData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200 && _.isEmpty(_.get(res, 'data.results'))) {
                setIsLoader(true);
                setTimeout(() => {
                  getChargerLog();
                }, 3000);
              } else {
                setIsLoader(false);
              }
            }
          },
        });
      }
    },
    [dateTime]
  );

  // const handlePageClick = (page) => {
  //   const data = {
  //     page: page,
  //   };
  //   getChargerLog(data);
  // };

  const getConfiguration = useCallback(
    (chargerId) => {
      const data = {
        id: chargerId,
      };
      setViewOnly(false)
      dispatch({
        type: GET_CONFIGURATION.REQUEST,
        payload: { data },
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setIsLoader(true);
              toast.success('Get configuration successfully');
              setDateTime(moment(moment.utc(new Date()).format()).toDate());
              setTimeout(() => {
                getChargerLog();
              }, 3000);
            } else {
              setIsLoader(false);
            }
          }
        },
      });
    },
    [chargerId]
  );

  let serialNum = limit * (page - 1);

  const changeConfigurationValue = (e) => {
    setConfigurationValue(e.target.value);
  };

  const changeConfigurationData = useCallback((data) => {
    dispatch({
      type: CHANGE_CONFIGURATION.REQUEST,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Change configuration successfully');
            getConfiguration(chargerId)
          }
        }
      },
    });
  }, []);

  const changeConfiguration = (key) => {
    const data = {
      key: key,
      value: configurationValue,
      id: chargerId,
    };
    changeConfigurationData(data);
  };

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const handleSearch = () => {
    getChargerLog({});
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('getConfigKey.formTitle')}</div>
      <Card className="mb-3">
        {!_.isEmpty(manageStationChargerOperations) || (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
          <>
            <Button
              onClick={() => {
                getConfiguration(chargerId);
              }}
            >
              {t('getConfigKey.getConfigKey')}
            </Button>
            <Button onClick={() => viewChargerLog()} className="ms-2">
            {t('getConfigKey.viewConfigKey')}
            </Button>
          </>
        ) : (
          <Button>{t('getConfigKey.getConfigKey')}</Button>
        )}
      </Card>
      <div className="server-operation-table-page_main">
        <div className="server-operation-table-main-block">
          <Card>
            <div className="server-operation-table-block">
              <div className="server-operation-search-box">
                <Row>
                  <Col>
                    <SearchBox
                      preIcon={<BsSearch />}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="server-operation-table_list">
                <div className="table-responsive">
                  <table className="record-list-table">
                    <thead>
                      <tr>
                        <th>{t('getConfigKey.srNo')}</th>
                        <th>
                          <div className="sorting">
                            <span>{t('getConfigKey.key')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{t('getConfigKey.configValue')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{t('getConfigKey.action')}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoader ? (
                        <tr>
                          <td colSpan={8} className="border-0">
                            <div className="loader_block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                              <span className="ps-2">{t('placeHolder.loading')}</span>
                            </div>
                          </td>
                        </tr>
                      ) : _.isEmpty(getAllChargerLog) ? (
                        <tr>
                          <td colSpan={8} className="border-0">
                            <div className="empty-data-block">{t('getConfigKey.noData')}</div>
                          </td>
                        </tr>
                      ) : (
                        !(isLoader || _.isUndefined(isLoader)) &&
                        _.map(getAllChargerLog, (item) => {
                          return (
                            _.get(item, 'response.configurationKey') &&
                            _.map(_.get(item, 'response.configurationKey'), (data, index) => {
                              const readOnly =
                                typeof _.get(data, 'readonly') === 'string' ? JSON.parse(data.readonly.toLowerCase()) : _.get(data, 'readonly');
                              return (
                                <tr key={`configuration-data-item-${index}`}>
                                  <td>{serialNum + (index + 1)}</td>
                                  <td>{_.get(data, 'key')}</td>
                                  <td>
                                    {!readOnly ? (
                                      <TextInput
                                        key={index}
                                        onChange={(e) => {
                                          changeConfigurationValue(e);
                                        }}
                                        type="text"
                                        name={_.get(data, 'key')}
                                        defaultValue={_.get(data, 'value')}
                                      />
                                    ) : (
                                      <TextInput
                                        disabled={true}
                                        key={index}
                                        onChange={(e) => {
                                          changeConfigurationValue(e);
                                        }}
                                        type="text"
                                        name={_.get(data, 'key')}
                                        defaultValue={_.get(data, 'value')}
                                      />
                                    )}
                                  </td>
                                  {
                                    <td>
                                      {
                                        (!readOnly ? (
                                          <div className="button--block" onClick={() => changeConfiguration(_.get(data, 'key'))} style={{ visibility: viewOnly ? 'hidden' : 'none' }}>
                                            <BiSave size={22} />
                                          </div>
                                        ) : (
                                          <div className="button--block" style={{ visibility: viewOnly ? 'hidden' : 'none' }}>
                                            <GoCircleSlash size={22} />
                                          </div>
                                        ))
                                      }
                                    </td>
                                  }
                                </tr>
                              );
                            })
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*{!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(getAllChargerLog) && (*/}
              {/*  <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />*/}
              {/*)}*/}
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfigurationList;
