import { get, downloadFile } from 'utils/api';
import { REPORT_LIST, REPORT_DOWNLOAD_REQUEST,GET_SIGNED_URL } from './endpointURLs';

export const fetchReportList = (data) => {
  return get(`${REPORT_LIST}`, data);
};

export const fetchReportDownloadRequest = (data) => {
  return get(`${REPORT_DOWNLOAD_REQUEST}`, data);
};

export const createReportDownloadRequest = (data) => {
  return downloadFile(`${REPORT_DOWNLOAD_REQUEST}`, data);
};

export const getSignedUrl = (id) => {
  return get(`${GET_SIGNED_URL}/${id}`);
};

export const reportDownloadRequest = {
  fetchReportList,
  fetchReportDownloadRequest,
  createReportDownloadRequest,
  getSignedUrl
};
